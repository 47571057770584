import React from 'react';
// import logo from './logo.svg';
import './App.css';

function Post(props) {
  const { title, username, content } = props
  return (
    <div className="Post">
      <p>Post</p>
      <ul>
        <li>title: {title}</li>
        <li>username: {username}</li>
        <li>content: {content}</li>
      </ul>
    </div>
  )
}

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postsData: [],
      title: '',
      username: '',
      content: ''
    };
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleContentChange = this.handleContentChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const url = "https://my-app.yangyq.workers.dev/post";

    fetch(url)
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response.json();
      })
      .then((data) => {
        console.log(data);
        this.setState({
          postsData: data,
        });
      })
      .catch((error) => console.log(error));
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
  }

  handleContentChange(event) {
    this.setState({ content: event.target.value });
  }

  handleSubmit(event) {
    const url = "https://my-app.yangyq.workers.dev/post";

    const { title, username, content } = this.state;

    const post = {
      "title": title,
      "username": username,
      "content": content
    };
    fetch(url, {
      headers:
      {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(post),
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response.json();
      })
      .then((data) => {
      })
      .catch((error) => console.log(error));
  }

  render() {
    const { postsData, title, username, content } = this.state;

    return (
      <div className="App">
        {/* <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header> */}
        <div className="row">
          <div className="column">
            {postsData.map((postData, index) => (
              <div key={index}>
                <Post title={postData["title"]}
                  username={postData["username"]}
                  content={postData["content"]} />
              </div>
            ))}
          </div>
          <div className="column">
            <p>New Post</p>
            <form onSubmit={this.handleSubmit}>
              <li>
                Title:
                <input type="text" value={title} onChange={this.handleTitleChange} />
              </li>
              <li>
                Username:
                <input type="text" value={username} onChange={this.handleUsernameChange} />
              </li>
              <li>
                Content:
                <input type="text" value={content} onChange={this.handleContentChange} />
              </li>
              <input type="submit" value="Submit" />
            </form>
          </div>
        </div>

      </div>
    );
  }
}
